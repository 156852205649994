import { createStore } from "vuex";

export default createStore({
    state: {
        isConnected: false,
    },
    mutations: {
        connected(state) {
            state.isConnected = true;
        },
        disconnected(state) {
            state.isConnected = false;
        },
    },
    actions: {
        connected({ commit }) {
            commit("connected");
        },
        disconnected({ commit }) {
            commit("disconnected");
        }
    },
    getters: {
        socketStatus(state) {
            return state.isConnected;
        }
    },

    namespaced: true,
    modules: {},
});
