<template>
    <apk-builder />
    <div class="connection-status" :class="isConnected ? 'connected' : 'disconnected'">
        {{ isConnected ? 'Connected   :)' : 'Disconnected   :(' }}
    </div>
</template>

<script>
import ApkBuilder from './components/ApkBuilder.vue'

export default {
    name: 'App',
    components: {
        ApkBuilder
    },
    data() {
        return {
        }
    },
    sockets: {
        connect() {
            this.$store.dispatch("connected");
        },
        disconnect() {
            this.$store.dispatch("disconnected");
        },

        http_exception(data) {
            if (data.message === null || data.message === undefined)
                return;

            let message = data.message;
            if (data.message.constructor === Array)
                message = data.message.join('\n');

            alert(message);
        },
        ws_exception(data) {
            console.log('ws_exception', data);
        },
    },
    computed: {
        isConnected() {
            return this.$store.getters["socketStatus"];
        }
    }
}
</script>

<style>
.connection-status {
    position: fixed;
    bottom: 10px;
    left: 10px;
    padding: 15px 25px;
    box-shadow: 0 0 2px #bbb;
    border-radius: 5px;
    font-weight: bold;
}
.connection-status.connected {
    color: #42b983;
}
.connection-status.disconnected {
    color: #B93D3D;
}
</style>
