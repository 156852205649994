import { createApp } from 'vue';
import store from './managers/stores';
import App from './App.vue';
import VueSocketIO from 'vue-3-socket.io'

// createApp(App).mount('#app')


const vueSocketIO = new VueSocketIO({
    connection: 'http://localhost:6500',
    options: { transports : ['websocket', 'polling', 'flashsocket'] }
});


let app = createApp(App)
app.use(store);
app.use(vueSocketIO)
app.mount('#app')
