<template>
    <div class="container">
        <section v-if="show_form">
            <header>Apk Builder</header>
            <div>
                <input type="text" placeholder="Title - Example google" v-model="input_title">
            </div>
            <div>
                <input type="text" placeholder="Url - Example https://google.com" v-model="input_url">
            </div>

            <div>
                <button type="button" @click="signal_build()">Build</button>
            </div>
        </section>

        <section v-if="!show_form">
            <section v-if="show_loading">
                <h2>Building APK, Please Wait ...</h2>
            </section>
            <section v-if="show_error">
                <div>
                    <header>Error !!!</header>
                </div>
            </section>
            <section v-if="show_download">
                <div>
                    <header>Download APK</header>
                    <a :href="link" target="_blank">Link</a>
                </div>
            </section>

            <section v-if="!show_loading" style="margin-top: 1rem">
                <a @click="display_form()">Back to build form</a>
            </section>


            <ul style="text-align: left;">
                <li v-for="(item, index) of step_list" :key="index">
                    {{ item }}
                </li>
            </ul>
        </section>
    </div>
</template>

<script>
export default {
    name: 'apk-builder',
    data() {
        return {
            input_title: 'Varzesh3',
            input_url: 'https://varzesh3.com',

            link: '',
            error: '',
            step_list: [],

            show_form: true,
            show_loading: false,
            show_error: false,
            show_download: false,

            accept_project_name: '',
            accept_title: '',
            accept_url: '',
        }
    },
    sockets: {
        step_create_response(data) { console.log('step_create_response', data);
            if (!data && !data.create) {
                this.display_error('create application.');
                return;
            }

            this.accept_project_name = data.project_name;
            this.step_list.push('application create done.');

            this.step_template_request();
        },
        step_template_response(data) { console.log('step_template_response', data);
            if (!data && !data.template) {
                this.display_error('copy template.');
                return;
            }

            this.step_list.push('copy template done.');
            this.step_dependencies_request();
        },
        step_dependencies_response(data) { console.log('step_dependencies_response', data);
            if (!data && !data.dependency) {
                this.display_error('install dependencies');
                return;
            }

            this.step_list.push('install dependencies created.');
            this.step_permissions_request();
        },
        step_permissions_response(data) { console.log('step_permissions_response', data);
            if (!data && !data.permission) {
                this.display_error('add permissions');
                return;
            }

            this.step_list.push('add permissions done.');
            this.step_signing_request();
        },
        step_signing_response(data) { console.log('step_signing_response', data);
            if (!data && !data.sign) {
                this.display_error('sign apk');
                return;
            }

            this.step_list.push('sign apk done.');
            this.step_pub_get_request();
        },
        step_pub_get_response(data) { console.log('step_pub_get_response', data);
            if (!data) {
                this.display_error('pub get');
                return;
            }

            this.step_list.push('pub get done.');
            this.step_build_request();
        },
        step_build_response(data) { console.log('step_build_response', data);
            if (!data && !data.build) {
                this.display_error('build apk');
                return;
            }

            this.step_list.push('build apk done.');
            this.step_move_request();
        },
        step_move_response(data) { console.log('step_move_response', data);
            if (!data && !data.move) {
                this.display_error('move apk');
                return;
            }

            this.link = data.link;
            this.step_list.push('move apk done.');

            this.step_remove_request();
        },
        async step_remove_response(data) { console.log('step_remove_response', data);
            if (!data && !data.remove) {
                this.display_error('remove project');
                return;
            }

            this.step_list.push('remove project done.');

            this.step_list.push('build done.');
            await this.delay(1.5);
            this.display_download();
        },

        step_exception(data) { console.log('step_exception', data);
            this.display_error(data);
        },
    },
    methods: {
        async signal_build() {
            try {
                if (this.input_title.trim().length <= 0 || this.input_url.trim().length <= 0) {
                    alert('Please fill all inputs')
                    return;
                }
                if (!this.input_url.trim().startsWith('https://')) {
                    alert('Url should start with https://')
                    return;
                }

                this.accept_title = this.input_title.trim().toLowerCase();
                this.accept_url = this.input_url.trim().toLowerCase();
                this.display_loading();

                this.step_create_request();
            } catch (ex) {
                this.display_error(ex.message);
            }
        },


        step_create_request() {
            let payload = {
                title: this.accept_title
            }
            this.socket_emit('create', payload);
        },
        step_template_request() {
            let payload = {
                project_name: this.accept_project_name,
                title: this.accept_title,
                url: this.accept_url,
            }
            this.socket_emit('template', payload);
        },
        step_dependencies_request() {
            let payload = {
                project_name: this.accept_project_name,
            }
            this.socket_emit('dependencies', payload);
        },
        step_permissions_request() {
            let payload = {
                project_name: this.accept_project_name,
            }
            this.socket_emit('permissions', payload);
        },
        step_signing_request() {
            let payload = {
                project_name: this.accept_project_name,
            }
            this.socket_emit('signing', payload);
        },
        step_pub_get_request() {
            let payload = {
                project_name: this.accept_project_name,
            }
            this.socket_emit('pub_get', payload);
        },
        step_build_request() {
            let payload = {
                project_name: this.accept_project_name,
            }
            this.socket_emit('build', payload);
        },
        step_move_request() {
            let payload = {
                project_name: this.accept_project_name,
                title: this.accept_title,
            }
            this.socket_emit('move', payload);
        },
        step_remove_request() {
            let payload = {
                project_name: this.accept_project_name
            }
            this.socket_emit('remove', payload);
        },




        display_form() {
            this.show_form = true;
            this.show_loading = false;
            this.show_error = false;
            this.show_download = false;
        },
        display_loading() {
            this.step_list = [];

            this.show_form = false;
            this.show_loading = true;
            this.show_error = false;
            this.show_download = false;
        },
        display_error(error) {
            this.step_list.push(`error in ${error}`);

            this.show_form = false;
            this.show_loading = false;
            this.show_error = true;
            this.show_download = false;
        },
        display_download() {
            this.show_form = false;
            this.show_loading = false;
            this.show_error = false;
            this.show_download = true;
        },

        delay(seconds) {
            return new Promise((resolve) => {
                setTimeout(resolve, seconds * 1000);
            });
        },

        async socket_emit(path, payload) {
            await this.delay(0.2);

            console.log('socket_emit', path, payload);
            this.$socket.emit(path, payload);
        }
    }
}
</script>

<style scoped>
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}
header {
    margin: 0 0 2rem 0;
    font-weight: bold;
    font-size: 3rem;
}
a {
    color: #42b983;
    font-size: 1.5rem;
    cursor: pointer !important;
}
input {
    font-size: 1.2rem;
    margin: 0 0 1rem 0;
    padding: 0.8rem;
    border-radius: 50rem;
}
button {
    font-size: 1.2rem;
    padding: 0.8rem;
    width: 100%;
    border-radius: 50rem;
}
</style>
